<template>
  <v-row>
    <v-col
      cols="12"
      md="9"
    >
      <v-card>
        <v-card-text>
          <v-list
            dense
          >
            <v-subheader>Доступы</v-subheader>

            <v-list-item-group
              multiple
            >
              <v-list-item v-for="item in permissions"  :key="item.permissionName">
                <template>
                  <v-list-item-action>
                    <v-checkbox v-model="item.isSelected"></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.permissionName }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col
      cols="12"
      md="3"
    >
      <v-card class="mb-6">
        <v-card-text>
          <v-btn
            class="mb-3"
            color="error"
            block
            outlined
            @click="cancel"
          >
            Отмена
          </v-btn>
          <v-btn
            class="mb-3"
            block
            color="success"
            outlined
            @click="submitData"
          >
            Применить
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex'


export default {
  name: 'RoleEdit',
  computed: {
    ...mapState('role/form', {
      permissions: 'permissions'
    }),
  },
  async created() {
    document.title = 'Редиктирование роли'
    const { id } = this.$route.params
    this.fetchPermissions(id);
  },
  methods: {
    ...mapActions('role/form', {
      fetchPermissions: 'fetchById',
      updatePermissions: 'update'
    }),
    async submitData(){
      await this.updatePermissions();
      this.$router.back()
    },
    cancel(){
      this.$router.back()
    }
  }
}
</script>

<style lang="scss">
</style>
